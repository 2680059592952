<template lang="pug">
  .banner-slider

    .container(v-if="$route.name !== 'productSingle' && typeEcom === 'pedrosa'" :class="{'noShow': (catActive && catActive.level != 0) || query}")
      .row
        .col-sm-12
          .adplugg-tag(data-adplugg-zone="slide_b2b")

    .container(class="!tw-h-auto")     
      .row
        .col-sm-12
          div(class="tw-bg-[#E7F4FC] tw-p-[12px]")
            span #[translate Filtrar por marca:]   
            .tw-flex(class="tw-mt-[10px]")
              button(v-for="(item, index) in $store.getters.catalogueSuppliersBanner" @click="setSupplier(item)" class="tw-border tw-border-[#E7F4FC] tw-border-[3px] tw-relative tw-rounded-[4px] tw-mr-[19px]" 
              :class="{'!tw-border-[#1592E6]': $store.getters.catalogueSupplier === item.id && $store.getters.catalogueSupplierImageBanner === item.image_url}")
                img(:src="item.image_url")
                span(v-if="$store.getters.catalogueSupplier === item.id && $store.getters.catalogueSupplierImageBanner === item.image_url" class="tw-absolute tw-top-[-5px] tw-right-[-5px] tw-w-[15px] tw-h-[15px] tw-bg-[#1592E6] tw-text-white tw-rounded-[15px] tw-flex tw-items-center tw-justify-center") x
</template>

<script>
import SrvCommon from '@/services/common.js'
export default {
  name: 'BannerSlider',
  props: ['catActive', 'query'],
  data () {
    return {
      img: {
        slider1: require('@/assets/img/banner/banner_home_1200x200.jpg'),
        slider2: require('@/assets/img/banner/banner_home_1200x200_slide2.jpg'),
        slider3: require('@/assets/img/banner/banner_home_1200x200_slide3.jpg'),
        flecha: require('@/assets/img/icos/flecha-01.svg')
      },
      suppliers: []
    }
  },
  async created(){
    
  },
  computed: {
    typeEcom () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    }
  },
  methods: {
    setSupplier(supplier) {
      if (this.$store.getters.catalogueSupplier === supplier.id && this.$store.getters.catalogueSupplierImageBanner === supplier.image_url) {
        this.$store.dispatch('setCatalogueSupplier', null)
        this.$store.dispatch('setCatalogueSupplierImageBanner', null)
      } else {
        this.$store.dispatch('setCatalogueSupplier', supplier.id)
        this.$store.dispatch('setCatalogueSupplierImageBanner', supplier.image_url)
        
        let supplierScroll = document.querySelector('#suppliers-'+supplier.id)
        if (supplierScroll) {
          console.log(supplierScroll.getBoundingClientRect().top, document.querySelector('#filterSupplier .scroll-cn').getBoundingClientRect().top)
          /*document.querySelector('#filterSupplier .scroll-cn').scrollTo({
            top: supplierScroll.getBoundingClientRect().top + document.querySelector('#filterSupplier .scroll-cn').getBoundingClientRect().top,
            left: 100,
            behavior: "smooth",
          });*/
          //supplierScroll.scrollIntoView()
        }
      }
    },
    setCategory (v) {
      this.$emit('toSetCategory', v)
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner-slider{
    & > .container{
      // margin-top: 16px;
      // background-color: rgba(#EFEFEF, .35);
      overflow: hidden;
      @include transition(.6);

      &.noShow{
        height: 0;
        // opacity: 0;
      }
    }
  }

  .carousel-control{
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin: auto;
    @include roundAll100(50%);
    background-color: rgba(#000, .3);

    &.left{
      left: 30px;
    }

    &.right{
      right: 30px;
      line-height: 54px;
      transform: rotateZ(180deg);
    }
  }
</style>
