<template lang="pug">
  #catalog-filterSb
    bk-menu( :category="category" @toFilterCategory="toFilterCategory" @toGetMigas="toGetMigas" @toGetCat="toGetCat")
    div(v-if="(role !== 'client' && isLoggedIn && !isPageCatalogoPublic && suppliers.length > 1) || (isTypeApp === 'maripol' && isLoggedIn && !isPageCatalogoPublic && suppliers.length > 1)")
      h2
        img(:src="img.hambur" width="12")
        translate Filtrar por Proveedores
      .supplier-cn
        form(id="filterSupplier" class="collapse in" aria-expanded="true")
            .scroll-cn.scrollCustom
              p(v-for="item in suppliers")
                input(type="radio" name="suppliers" :id="'suppliers-'+item.id" :value="item.id" @click="changeSuppliers(item.id)" :checked="item.id==supplier")
                label(:for="'suppliers-'+item.id") {{item.name}}
                  small(v-if="item.count>0") ({{item.count}})

    bk-banner-sidebar(@toSetCategory="setCategory")

</template>
<script>
import {mapGetters} from 'vuex'

import BkMenu from '@/components/catalog/Menu.vue'
import BkBannerSidebar from '@/components/catalog/banner/Sidebar.vue'

// importamos servicios Catálogo
import SrvCatalog from '@/services/catalog'

export default {
  name: 'CatalogFilterSidebar',
  props: ['category', 'query', 'typeProduct', 'filterByType'],
  components: {BkMenu, BkBannerSidebar},
  data () {
    return {
      complete: '', // para mostrar precios especiales si son autorizados o no --> a True muestra todo autorizados y no autorizados
      only_usual_products: '', // para mostrar los artículos más consumidos.
      add_extra_products: '', // para mostrar los productos extra de orbis.
      is_outlet: '', // para mostrar los artículos outlet.
      is_eco: '', // para mostrar los artículos eco.
      suppliers: [],
      precios: [],
      capacidades: [],
      aCat: [],
      img: {
        hambur: require('@/assets/img/icos/hambur.svg')
      }
    }
  },
  computed: {
    ...mapGetters([
      'role', 'isLoggedIn'
    ]),
    has_special_prices () {
      if (this.$store.getters.client) {
        return this.$store.getters.client.has_special_prices
      }
      return false
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    isPageCatalogoPublic () {
      if (
        this.config_app.catalogo_public &&
        (
          this.$route.name === 'catalogoListPublic' ||
          this.$route.name === 'productSinglePublic' ||
          this.$route.name === 'catalogoPublicSearch'
        )
      ) {
        return true
      }

      return false
    },
    supplier () {
      return this.$store.getters.catalogueSupplier
    }
  },
  created () {
    if (!this.isPageCatalogoPublic && ((this.role !== 'client' && this.isLoggedIn) || (this.isTypeApp === 'maripol' && this.isLoggedIn))) {
      this.getSuppliers()
    }
  },
  methods: {
    getSuppliers () {
      if (!this.typeProduct) {
        this.complete = (!this.has_special_prices) ? 'True' : this.complete
      }
      SrvCatalog.getSuppliers(this.query, this.category, this.complete, this.only_usual_products, this.is_outlet, this.is_eco, this.add_extra_products)
        .then(res => {
          // console.log('--->Suppliers: ', res)
          if (res.status === 200) {
            // console.log('---> Suppliers: ', res.data.results)
            // console.log('Cargamos los proveedores con completo: ' + this.complete + ' .')
            const aTodas = {'count': 0, 'id': 0, 'name': this.$gettext('Todos')}
            this.suppliers = [aTodas, ...res.data.results]
            // this.suppliers = this.sortedArrayById(this.suppliers)
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // console.log('----->' + error)
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    changeSuppliers (v) {
      v = (v === 0) ? '' : v
      let supplierTmp = v
      this.$store.dispatch('setCatalogueSupplier', supplierTmp) // enviamos a la store el proveedor seleccionado.
      if (v) {
        if (this.$store.getters.catalogueSuppliersBanner.filter(e => e.id == v)[0]){
          this.$store.dispatch('setCatalogueSupplierImageBanner', this.$store.getters.catalogueSuppliersBanner.filter(e => e.id == v)[0].image_url)
        }
      }
    },
    sortedArrayById (array) {
      return array.sort((a, b) => a.id - b.id)
    },
    toFilterCategory (v) {
      this.$emit('toFilterCategory', v)
    },
    toGetMigas (v) {
      this.$emit('toGetMigas', v)
    },
    toGetCat (aCat) {
      this.$emit('toGetCat', aCat)
    },
    setCategory (v) {
      // console.log('setCategory:', v)
      const aMigas = v.split('-')
      aMigas.map((item, index) => {
        window.$(`#catalog-menu button[data-id="${item}"]`).click()
      })
    }
  },
  watch: {
    query () {
      // this.supplier = null
      this.$store.dispatch('setCatalogueSupplier', '')
      if (!this.isPageCatalogoPublic) {
        this.getSuppliers()
      }
    },
    category () {
      // this.supplier = null
      this.$store.dispatch('setCatalogueSupplier', '')
      if (!this.isPageCatalogoPublic) {
        this.getSuppliers()
      }
    },
    typeProduct () {
      let type = this.typeProduct.toString()
      // PRODUCTOS DE ORBITA SOLO EN ORBISH (FUNCTION aFilterProductList FILTER-TOP.vue)
      if (type === 'add_extra_products') {
        this.complete = 'false'
        this.add_extra_products = 'True'
      } else if (type === 'special_price') {
        this.complete = 'false'
        this.add_extra_products = 'false'
      } else if (type === 'all' || !this.has_special_prices) {
        this.complete = 'True'
        this.add_extra_products = 'false'
      }
    },
    /*filterByType () {
      // console.log(`watch grid catálogo --> filterByType: ${this.filterByType}`)
      let type = this.filterByType.toString()
      if (type === 'only_usual_products') {
        this.only_usual_products = 'True' // filtrar por productos más consumidos.
        this.is_outlet = '' // filtar por outlet.
        this.is_eco = '' // filtar por eco.
      } else if (type === 'is_outlet') {
        this.only_usual_products = 'false'
        this.is_outlet = 'True'
        this.is_eco = ''
      } else if (type === 'is_eco') {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = 'True'
      } else {
        this.only_usual_products = 'false'
        this.is_outlet = ''
        this.is_eco = ''
      }
    }*/
  }
}
</script>
<style lang="scss" scoped>

  h2{
    position: relative;
    color: #303030;
    font-weight: $light;
    font-size:14px;
    padding: 22px 0 16px;
    margin: 0 0 12px;
    display: flex;
    align-items: center;

    &:after{
      content: '';
      position: absolute;
      bottom:0;
      left:10px;
      right:0;
      height: 1px;
      background-color: #E1E1E1;
    }

    img{
      margin-right: 10px;
    }
  }

  form{
    padding-left: 10px;
    padding-bottom: 16px;
    margin-bottom:10px;
    border-bottom: 1px solid #E1E1E1;

    p{
      position: relative;
      margin: 0;
      padding-left: 24px;
      padding-right: 24px;
      line-height: 22px;
    }

    input{
      position:absolute;
      top: 0;
      left:2px;
    }

    label{
      color:#646464;
      font-weight: $light;
      font-size:12px;
      margin: 0;

      small{
      }
    }
  }

  .scroll-cn{
    max-height: 440px;
    overflow: hidden;
    overflow-y: auto;
  }

  .category-cn{
    border-bottom: 1px solid #eff3f6;
    padding-bottom: 20px;
    p{
      margin:0;
    }
    button{
      background-color: transparent;
      border: 0;
      width: 100%;
      text-align: left;
      font-size: 12px;
      @include transition();
      margin-bottom: 4px;

      &:hover, &:focus{
        background-color: rgba($blue-light, 0.1);
      }
    }
  }
</style>
