<template lang="pug">
  .oferta-single
    .Loader-cn(:class="{'show': isPdfLoading}")
      span.Loader-spinner
    .box-search
      .container
        .row
          .col-sm-12
            a(href="#" @click="goBack()") #[translate Volver]
            // translate Oferta:
            // strong {{number}}
    .container(v-if="messageError")
      .row
        .col-sm-12
          p &nbsp;
          p(class="alert alert-warning fade in" role="alert") {{messageError}}

    .container(v-if="messageOk")
      .row
        .col-sm-12
          p &nbsp;
          p(class="alert alert-success fade in" role="alert") {{messageOk}}

    div(v-if="!messageError")

      .box-1
        .container
          .row
            .col-sm-8
              .date-cn
                span(v-if="isTypeApp === 'vario'") #[translate Fecha del presupuesto]:&nbsp;
                span(v-else) #[translate Fecha de envío del presupuesto]:&nbsp;
                strong {{ date | moment("DD-MM-YYYY")}}
            .col-sm-4.text-right
              button.btn.btn-action(@click="downloadOfferPdf({id,number}, true)") #[translate Descargar presupuesto]

      .container.table-1(:class="{'full-cn': isTypeApp === 'vario'}")
        .row.table-head(:class="{'justify-between': center && client}")
          .holder-cn(v-if="center")
            h3 #[translate Centro]
          .holder-cn(v-if="client")
            h3  #[translate Cliente]

        .row.table-grid(:class="{'justify-between': center && client}")
          .holder-cn(v-if="center")
            p {{center.name}}
            p {{center.address}}
            p ({{center.postal_code}}, {{center.locality}})
          .holder-cn(v-if="client")
            p {{client.name}}
            p {{client.address}}
            p ({{client.postal_code}}, {{client.locality}})

      .container.table-2
        .row.table-head
          .col-sm-4 {{numItems}} #[translate productos]
          .col-sm-2 &nbsp;
          .col-sm-2.text-right #[translate Precio Unidad]
          .col-sm-1.text-right #[translate Cantidad]
          .col-sm-1.text-right #[translate Formato]
          .col-sm-1.text-righ(v-if="productDiscount") #[translate Descuento]
          .col-sm-1.text-right(v-else) &nbsp;
          .col-sm-1.text-right #[translate total]

        .table-grid
          bk-item(v-for="item in items" :key="item.id" :product="item")
      // Ponemos 'kk' para ocultar el bloque a todos los ecom, así lo podemos activar si lo necesitan.
      .box-payment(v-if="isTypeApp === 'kk'")
        .container
          .row
            .col-sm-7
              img(:src="img.payment")
              translate Payment
            .col-sm-3
              strong #[translate Payment options]:
              em  XXXX
            .col-sm-2
              strong #[translate Discount]
              em -X,XX {{moneda}}
      .box-3
        .container
          .row(v-if="isTypeApp === 'vario'")
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li #[translate subtotal]: #[small {{base | format-money}} {{moneda}}]
                li #[translate Discounts]: #[small {{discounts}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]
          .row(v-else)
            .col-sm-8.obser-cn
              p(v-if="details")
                strong #[translate Notas]:
                small {{details}}
            .col-sm-4
              ul
                li #[translate Subtotal]: #[small {{final | format-money}} {{moneda}}]
                li(v-if="discounts > 0") #[translate Descuento]: #[small {{discounts | format-money}} %]
                li #[translate Importe final]: #[small {{base | format-money}} {{moneda}}]
                li #[translate IVA]: #[small {{tax | format-money}} {{moneda}}]
                li #[translate TOTAL] #[small {{total | format-money}} {{moneda}}]


      .container.box-4
        .row.tw-flex          
          div(class="tw-w-[12%]")
            router-link( :to="{ name: 'ofertasList'}" class="btn btn-cancel") #[translate Volver]
          div.tw-text-center(class="tw-ml-[2%] tw-w-[58%]" :class="{'error': errorEmptyPrintFormat}")
            div
              
              //.dropdown.Select-bk(v-if="printFormats.length > 1")
                button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPrintFormats" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click="errorEmptyPrintFormat=false") {{printFormatsName}}
                  i(class="fa fa-angle-down" aria-hidden="true")
                ul(class="dropdown-menu" aria-labelledby="ddPrintFormats")
                  li(v-for="item in printFormats" :class="{'disabled': printFormatsName == item.name}")
                    span(:value="item.id" @click="changeOption(item.id, item.name)") {{item.name}}
              button(v-if="center" class="btn btn-action !tw-bg-white !tw-text-black tw-border tw-border-black tw-ml-2 tw-mr-5" :class="{ 'btn-send': enviando}" @click="showConfigCatalog = true")
                span #[translate Convertir el presupuesto a Catálogo]
              span.tw-mr-1 #[translate Volver a enviar el presupuesto ]
              button(class="btn btn-action" :class="{ 'btn-send': enviando}" @click="sendPrintFormat()")
                span(v-if="!enviando") #[translate Enviar PDF]
                span(v-if="enviando") #[translate Enviando PDF]
          div.text-right.c-c(class="tw-w-[28%]" :class="{'error': errorEmptyTypeCart}")
            .dropdown.Select-bk(v-if="aTypeCart.length > 1")
              button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddTypeCart" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" @click="errorEmptyTypeCart=false") {{typeCartName}}
                i(class="fa fa-angle-down" aria-hidden="true")
              ul(class="dropdown-menu" aria-labelledby="ddTypeCart")
                li(v-for="item in aTypeCart" :class="{'disabled': typeCartName == item.name}")
                  span(:value="item.id" @click="changeOptionTypeCart(item.id, item.name)") {{item.name}}
            //button(class="btn btn-orange" :class="{ 'btn-send': enviandoTypeCart}" @click="sendCopyToCart()")
            span #[translate Enviar presupuesto ]
              button.btn.btn-orange.tw-ml-1(@click="selectClient()" data-toggle="modal" data-target="#modal-oferta" class="btn-modal" )  #[translate Seleccionar un cliente]
            //p
              small.msgError(:class="{'hidden': !errorEmptyTypeCart}") #[translate Selecciones un cliente popup]
            //button.hidden(data-toggle="modal" data-target="#modal-oferta" class="btn-modal" ref="showModal")
        .row.tw-border-t-2(v-if="showConfigCatalog" class="tw-border-[#17296E] !tw-mt-[30px]")
          // SI TENEMOS GENERADOR CATÁLOGOS
          transition(name="fade")
            div(v-if="config_app.catalog_generator && this.role !== 'client'")
              .col-sm-12.text-right
              .col-sm-4
              .col-sm-8
                div.col-sm-12#opcion-carrito-catalogo-pdf(class="!tw-border-t-0")
                  .row(class="!tw-mb-5")
                    .col-sm-6
                      div.tw-p-2(class="tw-bg-[#F1F4F7]")
                        span Cliente
                        .holder-cn(v-if="client")
                          p {{client.name}}
                          p {{client.address}}
                          p ({{client.postal_code}}, {{client.locality}})
                          //p.tw-mt-2 
                            button.btn.btn-orange(@click="selectClientForCatalog()" data-toggle="modal" data-target="#modal-oferta" class="btn-modal" )  #[translate Cambiar cliente para catálogo]
                    .col-sm-6
                      div.tw-p-2(class="tw-bg-[#F1F4F7]")
                        span Centro
                        .holder-cn(v-if="center")
                          p {{center.name}}
                          p {{center.address}}
                          p ({{center.postal_code}}, {{center.locality}})
                          //p.tw-mt-2(v-if="clientCatalog.center_count > 1")  
                            button.btn.btn-orange(@click="selectCenterForCatalog()" data-toggle="modal" data-target="#modalCenter" class="btn-modal" )  #[translate Cambiar centro para catálogo]
                        //p.tw-mt-2(v-if="!centerCatalog && clientCatalog.center_count > 1")  
                            button.btn.btn-orange(@click="selectCenterForCatalog()" data-toggle="modal" data-target="#modalCenter" class="btn-modal" )  #[translate Seleccionar centro para catálogo]
                  .row
                    .col-sm-6
                      p El Catálogo en PDF se enviará al email: {{ user.email }}
                    .col-sm-6.text-right
                      span #[translate Tipo de catálogo]:
                      .dropdown.Select-bk(class="!tw-w-[200px]")
                        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="ddPortes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{optionCatalog}}
                          i(class="fa fa-angle-down" aria-hidden="true")
                        ul(class="dropdown-menu" aria-labelledby="ddPortes")
                          li(v-for="(value, key)  in catalogs_types" :class="{'disabled': optionCatalog == key}")
                            span(:value="value" @click="changeOptionCatalogs(key, value)") {{value}}

                    .col-sm-6.text-right
                      span #[translate Tipo de Página]:

                      .dropdown.Select-bk
                        button.form-control(class="btn btn-default dropdown-toggle" type="button" id="perPage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true") {{optionCatalogItemPerPage}}
                          i(class="fa fa-angle-down" aria-hidden="true")
                        ul(class="dropdown-menu" aria-labelledby="perPage")
                          li(v-for="(value, key) in catalogs_items_per_page" :class="{'disabled': optionCatalogItemPerPage == key}")
                            span(:value="value" @click="changeOptionCatalogsItemsPerPage(key, value)") {{value}}
                  .row.tw-flex.tw-items-center
                    .col-sm-1
                    .col-sm-4.text-right
                      .mt-6.tr(for="nombrecatalogo") #[translate Nombre de la Catálogo]:
                    .col-sm-7.text-right
                      input#nombrecatalogo.form-control(v-model="catalogName")
                  .row.fileInputName.tw-flex.tw-items-center
                    .col-sm-1
                    .col-sm-4.text-right
                      div.text-right
                        p.mt-10 #[translate Añadir logotipo del cliente ]:
                    .col-sm-7.text-right
                        label.input-group-btn.w-20.d-table-cell
                          span.btn.btn-default.txt-c-blue #[translate Seleccionar archivo]
                            input(type="file" v-show="false" @change="previewFiles" accept=".png, .jpg")
                        input.w-80.btn.btn-default.form-control.showFileStatus.text-xs.gtext-left(type="text" :value="fileInputName" readonly)
              .col-sm-12.text-right
                p.submit-cn
                  router-link.tw-mr-2( :to="{ name: 'ofertasList'}" class="btn btn-cancel") #[translate Cancelar]
                  button(class="btn btn-action" :disabled="!numItems || (config_app.catalog_generator && (!optionCatalogId || !catalogName))" :class="{'btn-disabled': !numItems || (config_app.catalog_generator && (!optionCatalogId || !catalogName)), 'btn-send': enviando}" @click="convertQuotationInCatalog()")
                    span(v-if="!enviando") #[translate Convertir]
                    span(v-if="enviando") #[translate Convirtiendo]

    bk-modal-oferta(ref="modalOferta" :products="items")
    bk-modal-centers(ref="modalCenter")
</template>
<script>
import BkItem from '@/components/ofertas/Item-single.vue'
import BkModalOferta from '@/components/ofertas/Modal-content.vue'
import BkModalCenters from '@/components/center/Model-content.vue'
import mixinCommon from '@/mixins/commons.js'

// importamos servicios
import SrvOfertas from '@/services/ofertas'
import {mapGetters} from 'vuex'
export default {
  name: 'OfertaSingle',
  props: ['id'],
  mixins: [mixinCommon],
  components: { BkItem, BkModalOferta, BkModalCenters },
  data () {
    return {
      isPdfLoading: false,
      messageOk: null,
      messageError: null,
      number: null,
      date: null,
      base: null,
      discounts: null,
      final: null,
      tax: null,
      total: null,
      moneda: null,
      showConfigCatalog: null,
      optionCatalog: this.$gettext('Selecciona'),
      optionCatalogId: 0,
      optionCatalogItemPerPage: this.$gettext('Selecciona'),
      optionCatalogItemPerPageId: 0,
      catalogName: null,
      details: null,
      center: null,
      client: null,
      lead: null,
      printFormats: [],
      printFormatsName: this.$gettext('Volver a enviar como:'),
      printFormatsId: null,
      items: [],
      enviando: false,
      errorEmptyPrintFormat: false,
      errorEmptyTypeCart: false,
      typeCartName: this.$gettext('Convertir en nuevo/a:'),
      typeCartId: null,
      catalogs_types: null,
      catalogs_items_per_page: null,
      aTypeCart: [
        {'id': 1, 'name': this.$gettext('Presupuesto/pedido para cliente')}
      ],
      enviandoTypeCart: false,
      img: {
        loading: require(`@/assets/img/btn-loading2.svg`),
        payment: require(`@/assets/img/icos/payment.svg`)
      },
      fileInputName: this.$gettext('Ninguno seleccionado'),
      customerLogo: null
    }
  },
  computed: {
    ...mapGetters([
      'role'
    ]),
    user () {
      return this.$store.getters.user
    },
    config_app () {
      // console.log('----++', this.$config.configApp)
      return this.$config.configApp
    },
    numItems () {
      if (this.items) {
        return this.items.length
      }
      return 0
    },
    client_lead () {
      return (this.lead) ? this.$gettext('lead') : this.$gettext('cliente')
    },
    clientStore () {
      return this.$store.getters.client
    },
    clientCatalog () {
      return this.$store.getters.clientCatalog
    },
    centerCatalog () {
      return this.$store.getters.centerCatalog
    },
    leadStore () {
      return this.$store.getters.lead
    },
    isTypeApp () {
      // console.log(`--- typeApp: ${this.$config.type}`)
      return this.$config.type
    },
    productDiscount () {
      let productsWithDiscount = false
      this.items.forEach((ele) => {
        if (ele.discount_percentage > 0) {
          productsWithDiscount = true
        }
      })
      return productsWithDiscount
    }
  },
  created () {
    this.getOferta()
    this.getPrintFormats()
  },
  methods: {
    previewFiles (event) {
      let ext = event.target.files[0].name.split('.').pop()
      let size = event.target.files[0].size
      if (size < 2000000 && (ext === 'png' || ext === 'jpg')) {
        this.customerLogo = event.target.files[0]
        this.fileInputName = event.target.files[0].name
      } else {
        const msg = this.$gettext('Add logo in png or jpg format, max size 2Mb')
        this.showModalMsg(msg)
      }
    },
    changeOptionCatalogs (id, name) {
      // console.log(`----- ${v}`)
      this.optionCatalog = name
      this.optionCatalogId = id
    },
    changeOptionCatalogsItemsPerPage (id, name) {
      // console.log(`----- ${v}`)
      console.log(name, id)
      this.optionCatalogItemPerPage = name
      this.optionCatalogItemPerPageId = id
    },
    changeOption (id, name) {
      // console.log(`----- printFormatsId: ${id}, printFormatsName: ${name} `)
      this.printFormatsName = name
      this.printFormatsId = id
    },
    changeOptionTypeCart (id, name) {
      this.typeCartName = name
      this.typeCartId = id

      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      this.$refs.showModal.click()
      this.$refs.modalOferta.openModal(this.typeCartId)
    },
    getOferta () {
      SrvOfertas.getOferta(this.id)
        .then(res => {
          // console.log('getOferta: ', res.data)
          if (res.status === 200) {
            const data = res.data
            this.number = data.number
            this.date = data.offer_datetime
            this.base = res.data.base
            this.discounts = res.data.client_discount_percentage
            this.tax = res.data.tax_total
            this.final = res.data.final
            this.total = res.data.total
            this.moneda = data.currency_symbol
            this.items = res.data.entries
            this.details = data.details
            this.client = res.data.client
            this.$store.dispatch('setClientCatalog',res.data.client)
            this.center = res.data.center
            this.$store.dispatch('setCenterCatalog', res.data.center)
            this.lead = res.data.lead
            this.catalogs_types = res.data.catalogs_types
            this.catalogs_items_per_page = res.data.catalogs_items_per_page
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    getPrintFormats () {
      SrvOfertas.getPrintFormats()
        .then(res => {
          // console.log('getPrintFormats: ', res)
          if (res.status === 200) {
            const data = res.data
            // console.log('getPrintFormats: ', data)
            this.printFormats = data.results
          } else {
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
          }
        }, () => {
          // console.log('----->' + error)
          this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          window.scrollTo(0, 0)
        })
    },
    sendPrintFormat () {
      // console.log(`id: ${this.id}, printFormatsId: ${this.printFormatsId}`)
      if (!this.enviando) {
        this.enviando = true

        SrvOfertas.sendPrintFormat(this.id)
          .then(res => {
            // console.log('sendPrintFormat: ', res)
            if (res.status === 200) {
              this.messageOk = this.$gettext('Presupuesto enviado.')
              window.scrollTo(0, 0)
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              window.scrollTo(0, 0)
            }
            this.enviando = false
          }, () => {
            // console.log('----->' + error)
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
            this.enviando = false
          })
      }
    },
    convertQuotationInCatalog () {
      // console.log(`id: ${this.id}, printFormatsId: ${this.printFormatsId}`)
      if (!this.enviando) {
        this.enviando = true
        let formData = new FormData()
        // Add the form data we need to submit
        formData.append('catalog_name', this.catalogName)
        formData.append('catalog_type', this.optionCatalogId)
        formData.append('catalog_items_per_page', this.optionCatalogItemPerPageId)
        formData.append('catalog_details', this.observations)
        formData.append('client', this.client.id)
        if(this.center) {
          formData.append('center', this.center.id)
        }
        if (this.customerLogo) {
          formData.append('catalog_client_logo', this.customerLogo)
        }
        SrvOfertas.setOfertaCatalog(this.id, formData)
          .then(res => {
            // console.log('sendPrintFormat: ', res)
            if (res.status === 200) {
              this.messageOk = this.$gettext('Presupuesto convertido en catálogo correctamente.')
              window.scrollTo(0, 0)
            } else {
              this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
              window.scrollTo(0, 0)
            }
            this.enviando = false
          }, () => {
            // console.log('----->' + error)
            this.messageError = this.$gettext('Se ha producido un error. Intentelo más tarde.')
            window.scrollTo(0, 0)
            this.enviando = false
          })
      }
    },
    sendCopyToCart () {
      this.enviandoTypeCart = true
      this.replicarCartClient()
    },
    selectClient () {
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      // this.$refs.showModal.click()
      this.$refs.modalOferta.openModal(1)
    },
    selectClientForCatalog () {
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      // this.$refs.showModal.click()
      this.$refs.modalOferta.openModal(3)
    },
    selectCenterForCatalog () {
      // En el HTML hay un botón con el atributo ref="showModal" y de esta forma le hacemos click para mostrar la modal.
      // this.$refs.showModal.click()
      console.log(this.$refs.modalCenter)
      this.$refs.modalCenter.openModal()
    },
    replicarCartClient () {
      this.items.map((item, index) => {
        // console.log(`quantity: ${item.quantity}, product id: ${item.product.id}, client Id: ${this.clientStore.id}`)
        // console.log(`idProduct: ${item.idProduct}, quantity: ${item.quantity}, discount: ${item.discount}, product:`, item.product)
        item.product.discount = item.discount
        let idAditionalMeasurementUnit = item.aditional_measurement_unit ? item.aditional_measurement_unit.id : null
        this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: item.product, aditional_measurement_unit: idAditionalMeasurementUnit})
      })
      this.$router.push({'name': 'cartList'})
      return false
    },
    replicarCartLead () {
      this.items.map((item, index) => {
        // console.log(`quantity: ${item.quantity}, product id: ${item.product.id}, client Id: ${this.clientStore.id}`)
        item.product.discount = item.discount
        let idAditionalMeasurementUnit = item.aditional_measurement_unit ? item.aditional_measurement_unit.id : null
        this.$store.dispatch('addCart', {idProduct: item.product.id, quantity: item.quantity, product: item.product, aditional_measurement_unit: idAditionalMeasurementUnit})
      })
      this.$router.push({name: 'leadOfertaCartList'})
      return false
    },
    deleteCart () {
      this.$store.dispatch('deleteCart')
      // this.messageOk = this.$gettext('Carro eliminado.')
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
  .oferta-single{
    padding:0 0 200px;
  }
  #opcion-carrito-catalogo-pdf{
    border-top:1px solid rgb(194, 194, 194);
    border-bottom:1px solid rgb(194, 194, 194);

    margin: 15px 0;
    padding: 15px 0;
  }
  .box-search{
    padding: 14px 0;
    background-color: #EFEFEF;

    span{
      margin-left: 18px;
      font-weight: $medium;
    }

    strong{
      margin-left: 6px;
      font-weight: $medium;
    }

  }

  .alert{
    margin-top: 40px;
  }

  .box-1{
    padding: 26px 0 20px;
    font-size: 16px;
    small{
      font-size: 16px;
    }

    .date-cn{
      span{
        font-weight: $bold;
      }
    }

    strong{
      font-weight: $regular;
    }
  }

  .table-1{
    &.full-cn{
      .table-head, .table-grid{
        .holder-cn{
          width: 100%;
        }
      }
    }
    .table-head{
      display: flex;
      margin: 6px -15px 18px;

      .holder-cn{
        width: 48%;

        &:nth-child(3){
          h3 {
            font-weight: $medium;
          }
        }
      }

      h3{
        border-bottom: 1px solid #1C2C40;
        font-size: 14px;
        font-weight: $regular;
        padding-bottom: 6px;
      }

      p{
        margin: 0;
      }
    }
    .justify-between{
      justify-content: space-between;
    }
    .table-grid{
      display: flex;
      
      margin: 6px -15px 18px;
      font-size: 14px;
      color:#4D4F5C;

      .holder-cn{
        width: 48%;
        padding: 20px 18px;
        background-color: #F1F4F7;
      }

      strong{
        min-width: 130px;
        display: inline-block;
        font-weight: $regular;
      }
    }
  }

  .table-2{
    .table-head{
      margin: 6px 0;
      & > *{
        padding-bottom: 6px;
        border-bottom: 1px solid #979797;
        font-size: 14px;

        &:first-child{
          font-weight: $medium;
        }
      }

      .precio-und{
        padding-left: 0;
        padding-right: 0;
      }

      p{
        margin: 0;
      }
    }
  }

  .box-3{
    margin:24px 0 30px;

    .row{
      border-bottom: 2px solid #17296E;
      padding-bottom:16px;
      margin: 0;
    }

    ul{
      padding-left: 0;
      list-style: none;
    }

    li{
      color: #5b5d5e;
      font-size: 16px;
      margin-bottom: 14px;

      small{
        font-size: 16px;
        float: right;
      }

      &:last-child{
        font-size: 18px;
        font-weight: $medium;
      }
    }

    .obser-cn{
      strong{
        font-weight: $regular;
        color: #494f58;
        font-size: 14px;
        vertical-align: top;
        margin-right: 10px;
      }

      small{
        display: inline-block;
        font-size: 14px;
        width:70%;
        height: 80px;
      }
    }
  }


  .box-4{
    .row{
      margin: 0;
    }
    .c-l{
      padding-left:0;
    }
    .c-c{
      padding-right:0;

      & > *{
        margin-left: 10px;
      }

      &.error{
        color: $red;

        #ddPrintFormats, #ddTypeCart{
          border-color: $red;
          background-color: rgba($red, .05);
          color: $red;

          i{
            color: $red;
          }
        }
      }
    }

    .msgError{
      color: $red;
    }

    .btn{
      padding:0 20px;
      height: 42px;
      line-height: 42px;
      min-width: 126px;
      font-weight: 14px;
    }
  }

  .Select-bk{
    width: 240px;

    .dropdown-toggle{
      height: 42px!important;
      line-height: 42px!important;

      i{
        line-height: 40px!important;
      }
    }
  }
.row{
 &.fileInputName{
   margin-top:15px;
   margin-bottom:15px;
  .txt-c-blue {
      color: #00adfc;
  }
  input[type="file"]{
    padding: 8px 12px;
    height: 36px;
    line-height: normal;
    border: 1px solid #ccc;
    float:left;
  }
  input.showFileStatus{
    height: 44px;
    width: 80%;
    float:left;
    padding-left: 100px;
  }
  .w-20{
    width:20%;
    float:left;
  }
  .w-80{
    width:80%;
    float:left;
  }

 }
}
  .box-payment{
    .row{
      margin: -1px 0 0;
      padding: 30px 0;
      border-top: 2px solid #17296E;
      border-bottom: 2px solid #17296E;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > * {
        font-size: 14px;
        color: #1C2C40;

        &:first-child{
          padding-left:0;
          font-size: 16px;

          img{
            margin-right: 20px;
          }
        }

        &:last-child{
          text-align: right;
          padding-right: 0;

          em{
            background-color: #E2E2E2;
            min-width: 70px;
            height: 34px;
            line-height: 34px;
            text-align: right;
            display: inline-block;
            @include roundAll(2);
            padding: 0 8px;
            vertical-align: middle;
          }
        }

        strong{
          font-weight: $regular;
          margin-right: 20px;
        }

        em{
          font-style: normal;
          color: #646464;
        }
      }
    }
  }
</style>
